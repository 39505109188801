/**
 * @generated SignedSource<<a586ce93a31722e05e80e194af3ad999>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ChainIdentifier = "AMOY" | "APE_CHAIN" | "APE_CURTIS" | "ARBITRUM" | "ARBITRUM_GOERLI" | "ARBITRUM_NOVA" | "ARBITRUM_SEPOLIA" | "AVALANCHE" | "AVALANCHE_FUJI" | "B3" | "B3_SEPOLIA" | "BAOBAB" | "BASE" | "BASE_GOERLI" | "BASE_SEPOLIA" | "BERA_CHAIN" | "BLAST" | "BLAST_SEPOLIA" | "BSC" | "BSC_TESTNET" | "ETHEREUM" | "FLOW" | "FLOW_TESTNET" | "GOERLI" | "GUNZILLA_TESTNET" | "KLAYTN" | "MATIC" | "MONAD_TESTNET" | "MUMBAI" | "OPTIMISM" | "OPTIMISM_GOERLI" | "OPTIMISM_SEPOLIA" | "RINKEBY" | "RONIN" | "SAIGON_TESTNET" | "SEI" | "SEI_DEVNET" | "SEI_TESTNET" | "SEPOLIA" | "SHAPE" | "SOLANA" | "SOLDEV" | "SONEIUM" | "SONEIUM_MINATO" | "UNICHAIN" | "ZORA" | "ZORA_SEPOLIA" | "ZORA_TESTNET" | "%future added value";
export type OrderInvalidationReason = "ACCOUNT_DISABLED" | "ASSET_DISABLED" | "ASSET_OWNERSHIP_UPDATE" | "ASSET_REQUIRES_APPROVAL" | "CANCEL_TRANSACTION" | "COLLECTION_DISABLED" | "ERC20_APPROVAL_REVOKED" | "EXECUTION_REVERTED_ERROR" | "EXPIRED" | "GASLESS_CANCEL" | "INSUFFICIENT_BALANCE" | "MATCH_ORDERS_ERROR" | "NOT_EXECUTABLE" | "ON_CHAIN_VALIDATION_ERROR" | "ORDER_MALFORMED" | "PREMATCHED" | "TRAIT_OFFERS_DISABLED" | "TRANSACTION_SUBMISSION_ERROR" | "%future added value";
export type OrderV2OrderType = "BASIC" | "CRITERIA" | "DUTCH" | "ENGLISH" | "%future added value";
export type OrderV2Side = "ASK" | "BID" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type Orders_orders$data = ReadonlyArray<{
  readonly criteria?: {
    readonly collection: {
      readonly " $fragmentSpreads": FragmentRefs<"CollectionCell_collection">;
    };
    readonly trait: {
      readonly " $fragmentSpreads": FragmentRefs<"CollectionCell_trait">;
    } | null;
  } | null;
  readonly invalidationReason: OrderInvalidationReason | null;
  readonly isCancelled: boolean;
  readonly isValid: boolean;
  readonly item: {
    readonly __typename?: string;
    readonly acceptOfferDisabled?: {
      readonly __typename: "AcceptOfferDisabledType";
      readonly " $fragmentSpreads": FragmentRefs<"useAcceptOfferDisabledReason_data">;
    } | null;
    readonly assetQuantities?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly asset: {
            readonly decimals: number | null;
            readonly isDelisted: boolean;
            readonly ownedQuantity: string | null;
            readonly relayId: string;
          };
        } | null;
      } | null>;
    };
    readonly bundleCollection?: {
      readonly statsV2: {
        readonly floorPrice: {
          readonly eth: string;
        } | null;
      };
    } | null;
    readonly chain?: {
      readonly identifier: ChainIdentifier;
    };
    readonly collection?: {
      readonly statsV2: {
        readonly floorPrice: {
          readonly eth: string;
        } | null;
      };
    };
    readonly decimals?: number | null;
    readonly isDelisted?: boolean;
    readonly ownedQuantity?: string | null;
    readonly relayId?: string;
    readonly " $fragmentSpreads": FragmentRefs<"AcceptOfferDisabledWarningIcon_asset" | "ItemCell_data" | "asset_url">;
  };
  readonly maker: {
    readonly address: string;
    readonly " $fragmentSpreads": FragmentRefs<"AccountLink_data" | "wallet_accountKey">;
  };
  readonly openedAt: string;
  readonly orderType: OrderV2OrderType;
  readonly payment: {
    readonly relayId: string;
    readonly symbol: string;
  };
  readonly perUnitPriceType: {
    readonly eth: string;
    readonly usd: string;
  };
  readonly relayId: string;
  readonly remainingQuantityType: string;
  readonly side: OrderV2Side | null;
  readonly taker: {
    readonly address: string;
    readonly " $fragmentSpreads": FragmentRefs<"AccountLink_data" | "wallet_accountKey">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"AcceptOfferButton_order" | "BuyNowButton_orders" | "CancelOrderButton_data" | "ExpirationDate_data" | "ItemAddToCartButton_order" | "OrderPrice" | "OrderStatus_order" | "OrderUsdPrice" | "QuickBuyButton_order" | "useFulfillSemiFungibleOrders_orders" | "useIsQuickBuyEnabled_order">;
  readonly " $fragmentType": "Orders_orders";
}>;
export type Orders_orders$key = ReadonlyArray<{
  readonly " $data"?: Orders_orders$data;
  readonly " $fragmentSpreads": FragmentRefs<"Orders_orders">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = [
  (v0/*: any*/),
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "AccountLink_data"
  },
  {
    "kind": "InlineDataFragmentSpread",
    "name": "wallet_accountKey",
    "selections": (v1/*: any*/),
    "args": null,
    "argumentDefinitions": ([]/*: any*/)
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "relayId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "ChainType",
  "kind": "LinkedField",
  "name": "chain",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "identifier",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "decimals",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "identity",
      "value": {}
    }
  ],
  "kind": "ScalarField",
  "name": "ownedQuantity",
  "storageKey": "ownedQuantity(identity:{})"
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isDelisted",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "eth",
  "storageKey": null
},
v10 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CollectionStatsV2Type",
    "kind": "LinkedField",
    "name": "statsV2",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PriceType",
        "kind": "LinkedField",
        "name": "floorPrice",
        "plural": false,
        "selections": [
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "expandedMode"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "includeCriteriaTakerAsset"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "isBid"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "isSingleAsset"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "Orders_orders",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isValid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCancelled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "openedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "orderType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "remainingQuantityType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountType",
      "kind": "LinkedField",
      "name": "maker",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PaymentAssetType",
      "kind": "LinkedField",
      "name": "payment",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "symbol",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "item",
      "plural": false,
      "selections": [
        (v4/*: any*/),
        (v3/*: any*/),
        (v5/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "kind": "InlineDataFragmentSpread",
              "name": "asset_url",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AssetContractType",
                  "kind": "LinkedField",
                  "name": "assetContract",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "tokenId",
                  "storageKey": null
                },
                (v5/*: any*/)
              ],
              "args": null,
              "argumentDefinitions": []
            },
            (v6/*: any*/),
            {
              "condition": "isSingleAsset",
              "kind": "Condition",
              "passingValue": false,
              "selections": [
                (v7/*: any*/)
              ]
            },
            (v8/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "AcceptOfferDisabledType",
              "kind": "LinkedField",
              "name": "acceptOfferDisabled",
              "plural": false,
              "selections": [
                (v4/*: any*/),
                {
                  "condition": "includeCriteriaTakerAsset",
                  "kind": "Condition",
                  "passingValue": false,
                  "selections": [
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "useAcceptOfferDisabledReason_data"
                    }
                  ]
                }
              ],
              "storageKey": null
            },
            {
              "condition": "includeCriteriaTakerAsset",
              "kind": "Condition",
              "passingValue": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "AcceptOfferDisabledWarningIcon_asset"
                }
              ]
            }
          ],
          "type": "AssetType",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "first",
                  "value": 30
                }
              ],
              "concreteType": "AssetQuantityTypeConnection",
              "kind": "LinkedField",
              "name": "assetQuantities",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AssetQuantityTypeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AssetQuantityType",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "AssetType",
                          "kind": "LinkedField",
                          "name": "asset",
                          "plural": false,
                          "selections": [
                            (v3/*: any*/),
                            (v8/*: any*/),
                            (v7/*: any*/),
                            (v6/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": "assetQuantities(first:30)"
            }
          ],
          "type": "AssetBundleType",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "side",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountType",
      "kind": "LinkedField",
      "name": "taker",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "invalidationReason",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PriceType",
      "kind": "LinkedField",
      "name": "perUnitPriceType",
      "plural": false,
      "selections": [
        (v9/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "usd",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OrderPrice"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OrderUsdPrice"
    },
    {
      "condition": "isBid",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "item",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CollectionType",
                  "kind": "LinkedField",
                  "name": "collection",
                  "plural": false,
                  "selections": (v10/*: any*/),
                  "storageKey": null
                }
              ],
              "type": "AssetType",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": "bundleCollection",
                  "args": null,
                  "concreteType": "CollectionType",
                  "kind": "LinkedField",
                  "name": "collection",
                  "plural": false,
                  "selections": (v10/*: any*/),
                  "storageKey": null
                }
              ],
              "type": "AssetBundleType",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "OrderCriteriaType",
          "kind": "LinkedField",
          "name": "criteria",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CollectionType",
              "kind": "LinkedField",
              "name": "collection",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "CollectionCell_collection"
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "TraitType",
              "kind": "LinkedField",
              "name": "trait",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "CollectionCell_trait"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    },
    {
      "condition": "expandedMode",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "item",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ItemCell_data"
            }
          ],
          "storageKey": null
        }
      ]
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CancelOrderButton_data"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExpirationDate_data"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ItemAddToCartButton_order"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "QuickBuyButton_order"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useIsQuickBuyEnabled_order"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "skipOwnedQuantity",
          "variableName": "isSingleAsset"
        }
      ],
      "kind": "FragmentSpread",
      "name": "AcceptOfferButton_order"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useFulfillSemiFungibleOrders_orders"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BuyNowButton_orders"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OrderStatus_order"
    }
  ],
  "type": "OrderV2Type",
  "abstractKey": null
};
})();

(node as any).hash = "d9fe4b3a7cc890c7044eff8700f86a01";

export default node;
